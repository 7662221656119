<template>
	<div class="leads-kanban" v-if="getPermission('lead:view')">
		<div class="kanban-view-list">
			<v-flex
				v-for="(row, index) in this.leadData"
				:key="`${index}${new Date().getTime()}`"
				class="kanban-innerlist"
			>
				<div
					class="list-title"
					:class="`${row.status ? row.status.status_color : ''} lighten-4 list-title`"
				>
					<div class="d-flex">
						<div class="fw-600 title-text">{{ row.status.text }}</div>

						<v-spacer></v-spacer>
						<div class="fw-500 mr-2">
							<v-chip label color="green white--text" x-small v-if="row && row.list">
								<span class="fw-500" v-if="row && row.list">
									{{ totalAmount(row.list) }}
								</span>
							</v-chip>
						</div>
					</div>
				</div>
				<LeadKanbanList
					v-if="row"
					:key="new Date().getTime()"
					v-on:drop="onDrop"
					v-on:child-payload="getChildPayload"
					v-on:open-reminder="openReminder"
					v-on:open-remark="openRemark"
					@update:lead="update_lead($event)"
					:lead-details="row"
					pagination-end-point="lead-kanban-view"
				/>
			</v-flex>

			<template>
				<FullDialog :dialog="remarkDialog" :dialog-width="1200">
					<template v-slot:title>{{ leadBarcode }} Notes</template>
					<template v-slot:body>
						<AllNotesTab
							:relatedId="lead_id"
							:relatedType="'lead-notes'"
							create-url="lead-note"
							get-url="lead-note"
							v-on:refress="getLeadListing"
							:is-dialog="true"
						>
						</AllNotesTab>
					</template>
					<template v-slot:action>
						<v-btn
							class="close-btn"
							depressed
							tile
							:disabled="pageLoading"
							v-on:click="closeDialog('remark')"
						>
							Close
						</v-btn>
					</template>
				</FullDialog>
			</template>

			<template>
				<Dialog :dialog="reminderDialog" :dialog-width="1000">
					<template v-slot:title>Lead {{ leadBarcode }} Reminder</template>
					<template v-slot:body>
						<v-form v-model="reminderFormValid">
							<div>
								<div class="drawer-content pt-5 px-5">
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500">Title</label>
										</div>
										<v-text-field
											depressed
											hide-details
											outlined
											placeholder="Title"
											v-model="reminder.title"
											:disabled="pageLoading"
											:loading="pageLoading"
										></v-text-field>
									</div>
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500">Reminder Type</label>
										</div>
										<AutoCompleteInput
											dense
											id="reminder-type"
											hide-details
											placeholder="Select Reminder Type"
											:items="reminder_type_items"
											:disabled="pageLoading"
											:loading="pageLoading"
											v-model="reminder.type"
											item-text="text"
											item-value="value"
											v-on:change="get_reminder_values()"
										>
										</AutoCompleteInput>
									</div>
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500 required">Start Date</label>
										</div>
										<DateTimePicker
											dense
											id="reminder-start-date"
											hide-details
											:disabled="pageLoading"
											:loading="pageLoading"
											v-model="reminder.start_date"
											:placeholder="reminder.type != 1 ? 'Select Start Date...' : 'Select Start Date...'"
											v-on:change="get_reminder_values()"
											:rules="[
												vrules.required(reminder.start_date, reminder.type != 1 ? 'Start Date' : 'Start Date'),
											]"
											:class="{ required: !reminder.start_date }"
										></DateTimePicker>
									</div>
									<div class="mb-3" v-if="reminder.type != 1">
										<div class="d-flex pt-1">
											<label class="fw-500">Frequency</label>
										</div>
										<TextInput
											v-if="false"
											dense
											id="reminder-frequency"
											hide-details
											type="number"
											placeholder="Enter Frequency..."
											:disabled="pageLoading"
											:loading="pageLoading"
											class="mt-0"
											v-model="reminder.frequency"
											v-on:keyup="get_reminder_values()"
										></TextInput>
										<v-text-field
											type="Number"
											v-mask="'####'"
											depressed
											hide-details
											outlined
											placeholder="Enter Frequency..."
											:disabled="pageLoading"
											:loading="pageLoading"
											v-model="reminder.frequency"
											v-on:keyup="get_reminder_values()"
											@wheel="$event.target.blur()"
										></v-text-field>
									</div>
									<div class="mb-3" v-if="reminder && reminder.type != 1">
										<div class="d-flex pt-1">
											<label class="fw-500">End Date</label>
										</div>
										<DateTimePicker
											dense
											id="reminder-end-date"
											hide-details
											placeholder="Select End Date..."
											:disabled="pageLoading"
											:loading="pageLoading"
											readonly
											v-model="reminder.end_date"
										></DateTimePicker>
									</div>
									<div class="mb-3">
										<v-radio-group row hide-details class="mt-0" v-model="reminder.callType">
											<v-radio color="green" label="Call" value="call"></v-radio>
											<v-radio color="red" label="Email" value="email"></v-radio>
										</v-radio-group>
									</div>
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500 required">Attendees</label>
										</div>
										<v-autocomplete
											:items="userList"
											item-text="display_name"
											item-value="id"
											depressed
											hide-details
											outlined
											hide-selected
											placeholder="Select Attendees"
											multiple
											color="blue darken-4"
											class="cuatom-drop"
											:rules="[vrules.required(reminder.attendees, 'Attendees')]"
											small-chips
											deletable-chips
											v-model="reminder.attendees"
											:disabled="pageLoading"
											:loading="pageLoading"
											:class="{
												required: !reminder.attendees,
											}"
										>
											<template v-slot:selection="data">
												<v-chip
													small
													v-bind="data.attrs"
													:input-value="data.selected"
													close
													@click="data.select"
													@click:close="remove(data.item)"
												>
													<v-avatar left>
														<img v-if="data.item.profile_img" :src="data.item.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
													</v-avatar>
													{{ data.item.display_name }}
												</v-chip>
											</template>
											<template v-slot:item="data">
												<template>
													<v-list-item-avatar>
														<!-- <img :src="data.item.profile_img" /> -->
														<img v-if="data.item.profile_img" :src="data.item.profile_img" />
														<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
													</v-list-item-content>
												</template>
											</template>
										</v-autocomplete>
									</div>
									<div class="mb-3">
										<div class="d-flex pt-1">
											<label class="fw-500">Description</label>
										</div>
										<ckeditor
											:editor="editor"
											v-model="reminder.description"
											:config="editorConfig"
										></ckeditor>
									</div>
								</div>
							</div>
						</v-form>
					</template>
					<template v-slot:action>
						<v-btn
							class="white--text"
							depressed
							color="blue darken-4"
							:disabled="!reminderFormValid"
							tile
							v-on:click="update_or_create()"
						>
							Save
						</v-btn>
						<v-btn depressed tile :disabled="pageLoading" v-on:click="closeDialog('reminder')">
							Close
						</v-btn>
					</template>
				</Dialog>
			</template>
			<template>
				<CreateDialog :dialog="updateDialog" :dialog-width="1200">
					<template v-slot:body>
						<UpdateLead
							is-dialog
							:leads-id="lead_id"
							v-on:close="updateDialog = false"
							v-on:refresh="
								updateDialog = false;
								getLeadListing();
							"
						>
						</UpdateLead>
					</template>
				</CreateDialog>
			</template>
			<DeleteTemplate
				type="lead"
				:deleteUrl="delURL"
				:deleteText="delTEXT"
				:deleteDialog="confirm_dialog"
				v-on:close="confirm_dialog = false"
				v-on:success="refresh"
			></DeleteTemplate>
			<add-dialog
				:document-dialog="openUploadModel"
				v-on:close="openUploadModel = false"
				v-on:success="getReminderRemark(reminder_id)"
				:related-id="reminder_id"
				:parent-id="lead_id"
				:chat="0"
				:related-type="'lead-reminder-remark'"
			></add-dialog>
		</div>
	</div>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}
.kanban-view-list .kanban-innerlist .v-input--selection-controls.v-input {
	position: absolute;
	right: -8px;
	top: 50px;
}
.v-input--selection-controls {
	margin-top: 4px;
	padding-top: 4px;
	margin-right: 30px;
}
</style>
<script>
import MomentJS from "moment-timezone";
import { QUERY, PATCH, POST, GET, PUT } from "@/core/services/store/request.module";
import { cloneDeep, reduce, toNumber } from "lodash";
import Dialog from "@/view/components/Dialog";
import CreateDialog from "@/view/components/Create-dialog";
import { LeadEventBus } from "@/core/lib/leadremark.lib";
import FullDialog from "@/view/components/FullDialog";
import UpdateLead from "@/view/pages/leads/Create-Lead.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { map } from "lodash";
import MainMixin from "@/core/mixins/main.mixin.js";
import LeadKanbanList from "@/view/components/LeadKanbanList";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextInput from "@/view/components/TextInput";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import AddDialog from "@/view/pages/leads/create/AddDialog";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import { last, toString } from "lodash";
import DateTimePicker from "@/view/components/DateTimePicker";

export default {
	name: "LeadsKanban",
	mixins: [MainMixin],
	data() {
		return {
			dropPlaceholderOptions: {
				className: "lead-drop-preview",
				animationDuration: "50",
				showOnTop: true,
			},
			manageNote: false,
			reminder_create: false,
			totalAmountData: 0,
			pageLoading: false,
			leadData: [],
			updateDialog: false,
			allLeadData: [],
			remarkDialog: false,
			reminderDialog: false,
			userList: [],
			timeList: [],
			statusdata: null,
			confirm_dialog: false,
			formValid: true,
			leadBarcode: null,
			icon: "mdi-plus",
			remarkMsg: [],
			viewType: true,
			openUploadModel: false,
			reminderMsg: [],
			leadId: 0,
			lead_id: 0,
			pageStatus: null,
			delURL: null,
			delTEXT: null,
			allPagaData: [],
			default_date: null,
			default_remakr: null,
			remarkdata: {
				lead_remark: null,
			},
			reminderChat: {
				reminder_remark: null,
				related_type: "lead-reminder-remark",
				reminder_id: 0,
			},
			editor: ClassicEditor,
			editorData: "Description",
			editorConfig: {
				// The configuration of the editor.
			},
			date_dialog: false,
			from_date_dialog: false,
			reminder_id: 0,
			related_type: "lead",
			reminder_type_items: [
				{ value: 1, text: "One Time Reminder" },
				{ value: 2, text: "Daily Reminder" },
				{ value: 3, text: "Weekly Reminder" },
				{ value: 4, text: "Monthly Reminder" },
				{ value: 5, text: "Quarterly Reminder" },
				{ value: 6, text: "Half Yearly Reminder" },
			],
			reminder: {
				title: null,
				start_date: this.formatDateTimeRaw(new Date()),
				end_date: null,
				type: 1,
				frequency: 1,
				callType: "call",
				related_to: null,
				related_id: 0,
				description: null,
				attendees: [],
			},
			reminderFormValid: true,
		};
	},
	props: {},
	watch: {
		$route: {
			handler() {
				const { query } = this.$route;
				this.getLeadListing(query);
				/*
				this.getLeadListing(query);
				this.$route.query.date = []; */
			},
		},
	},

	methods: {
		/* formatMoney(money) {
					return formatMoney(money, {
						symbol: "$",
						decimal: ".",
						thousand: ",",
						precision: 2,
						format: "%s%v",
					});
				}, */
		refresh() {
			this.confirm_dialog = false;
			this.getLeadListing();
		},
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		leadPinned(id, status, type, data) {
			this.$store
				.dispatch(PATCH, {
					url: `pin-lead/${id}`,
					data: {
						value: data,
						type: type,
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Lead Pinned successfully." },
					]);
					this.getLeadListing();
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		FileDownload(data) {
			window.open(data, "_blank");
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		totalAmount(data) {
			let result = reduce(
				data,
				(acc, row) => {
					acc = toNumber(acc) + toNumber(row.amount);
					return acc;
				},
				0
			);
			return this.formatMoney(result);
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		get_reminder_values() {
			let reminderParseDate = MomentJS(this.reminder.start_date);
			/* let test = MomentJS(reminderParseDate).add(6, 'M');
			console.log(test.format('YYYY-MM-DD')); */
			if (this.reminder.start_date) {
				for (var i = 0; i < this.reminder.frequency; i++) {
					if (this.reminder.type == 6) {
						/* Half Yearly */
						reminderParseDate = MomentJS(reminderParseDate).add(6, "months");
					}

					if (this.reminder.type == 5) {
						/* Quarterly */
						reminderParseDate = MomentJS(reminderParseDate).add(3, "months");
					}

					if (this.reminder.type == 4) {
						/* Monthly */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "months");
					}

					if (this.reminder.type == 3) {
						/* Weekly */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}

					if (this.reminder.type == 2) {
						/* Daily */
						reminderParseDate = MomentJS(reminderParseDate).add(1, "days");
					}

					if (this.reminder.type == 1) {
						/* One Time */
						reminderParseDate = MomentJS(reminderParseDate).add(7, "days");
					}
				}

				return (this.reminder.end_date = reminderParseDate.format("YYYY-MM-DD"));
			}
		},

		update_lead(id) {
			this.$emit("update:lead", id);
			/* this.updateDialog = true;
			this.lead_id = id; */
		},

		pushToRoute(id) {
			this.$router.push({
				name: "leads-detail",
				params: { id },
				query: { t: new Date().getTime(), viewType: true },
			});
		},
		delete_lead(row) {
			this.delTEXT = row.title;
			(this.delURL = `lead/${row.id}`), (this.confirm_dialog = true);
			this.getLeadListing();
		},
		onSubmitReminderChat() {
			this.reminderChat.reminder_id = this.reminder_id;
			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `reminder-remark/${this.lead_id}`, data: this.reminderChat })
				.then(() => {
					this.reminderChat.reminder_remark = "";
					this.getReminderRemark(this.reminderChat.reminder_id);
					this.getLeadListing();
					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			if (this.reminder_id > 0) {
				this.updateReminderData();
			} else {
				this.saveReminderData();
			}
		},
		saveReminderData() {
			this.pageLoading = true;
			this.reminder.related_id = this.lead_id;
			this.reminder.related_to = "lead";
			this.$store
				.dispatch(POST, { url: `reminder`, data: this.reminder })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder added successfully." },
					]);
					this.reminderDialog = false;
					this.getLeadListing();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		updateReminderData() {
			this.reminder.related_id = this.lead_id;
			this.reminder.related_to = "lead";
			this.pageLoading = true;

			this.$store
				.dispatch(PUT, {
					url: `reminder/${this.reminder_id}`,
					data: this.reminder,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder updated successfully." },
						(this.reminderDialog = false),
						this.getLeadListing(),
					]);

					this.$refs.form.reset();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		openRemark(data) {
			this.leadBarcode = data.barcode;
			this.default_remakr = data.remark;
			this.default_date = toString(MomentJS(data.added_at));
			this.lead_id = data.id;
			/* this.getRemark(data.id); */

			this.remarkDialog = true;
		},
		openReminder(data) {
			this.leadBarcode = data.barcode;
			this.default_remakr = data.remark;
			this.default_date = toString(MomentJS(data.added_at));
			this.lead_id = data.id ? data.id : 0;
			if (data && data.reminder) {
				this.reminder_id = data.reminder.id ? data.reminder.id : 0;
			}
			if (this.reminder_id > 0) {
				this.getReminder();
			}
			/* if (this.reminder_id) {
				this.getReminderRemark(this.reminder_id);
			} */
			this.reminderDialog = true;
		},
		getReminderSetting() {
			this.$store
				.dispatch(GET, { url: `meeting-setting` })
				.then((data) => {
					this.timeList = data.time;
					this.userList = data.users;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		closeDialog(value) {
			if (value == "reminder") {
				this.reminderDialog = false;

				this.getLeadListing();
			} else {
				this.remarkDialog = false;
			}
		},

		getRemark(lead_id) {
			this.$store
				.dispatch(GET, {
					url: `lead-remark/lead-remark-notes/${lead_id}`,
				})
				.then((data) => {
					this.remarkMsg = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getReminderRemark(reminder_id) {
			this.$store
				.dispatch(QUERY, {
					url: `reminder-remark/${this.lead_id}/${reminder_id}`,
					data: { related_type: "lead-reminder-remark" },
				})
				.then((data) => {
					this.reminderMsg = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getReminder() {
			this.reminder = {
				id: 0,
				title: null,
				start_date: null,
				end_date: null,
				type: 1,
				frequency: 1,
				related_to: null,
				related_id: 0,
				description: null,
				attendees: [],
				added_atFormated: null,
			};
			if (this.reminder_id > 0) {
				this.$store
					.dispatch(GET, {
						url: `single-reminder/${this.reminder_id}/${this.related_type}`,
					})
					.then((data) => {
						this.reminder.id = data.reminder.id;
						this.reminder.title = data.reminder.title;
						this.reminder.start_date = MomentJS(data.reminder.start_date, "YYYY-MM-DD HH:II:SS").format(
							"YYYY-MM-DD HH:II:SS"
						);
						(this.reminder.end_date = data.reminder.end_date
							? MomentJS(data.reminder.end_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
							: null),
							(this.reminder.type = data.reminder.type);
						this.reminder.frequency = data.reminder.frequency ? data.reminder.frequency : 1;
						this.reminder.related_to = data.reminder.related_to;
						this.reminder.related_id = data.reminder.related_id;
						this.reminder.description = data.reminder.description;
						this.reminder.added_at = MomentJS(data.reminder.added_at).format("LLLL");
						this.reminder.added_atFormated = MomentJS(data.reminder.added_at).format("hh:mm A");
						this.reminder.attendees = map(data.attendie[0].attendies, "id");
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},
		remove(item) {
			const index = this.reminder.attendees.indexOf(item.id);
			this.reminder.attendees.splice(index, 1);
		},
		getLeadListing(query) {
			this.pageLoading = true;
			let _this = this;
			this.$store
				.dispatch(QUERY, {
					url: `lead-kanban-view`,
					data: {
						status: "all",
						...query,
					},
				})
				.then((data) => {
					_this.$nextTick(() => {
						_this.leadData = data;
					});
					this.pageLoading = false;
				})
				.catch((error) => {
					console.log({ error });
				});
		},

		onDrop(values) {
			const { index, collection, dropResult, dropStatus } = values;
			const { addedIndex } = dropResult;

			if (addedIndex != null) {
				this.statusdata = dropStatus;
			}

			if (addedIndex != null) {
				this.update_loading = true;
				this.$store
					.dispatch(PATCH, { url: `lead/${this.leadId}/${this.statusdata}` })
					.then(() => {
						//this.getLeadListing();
					})
					.catch((error) => {
						this.logError(error);
					})
					.finally(() => {
						this.update_loading = false;
					});
			}
			this.leadData[index].list = this.applyDrag(collection, dropResult);
		},

		getChildPayload(values) {
			const { row, index, event } = values;
			this.leadId = row[event].id;
			return this.leadData[index];
		},

		applyDrag(output, dragResult) {
			const { removedIndex, addedIndex, payload } = dragResult;

			if (removedIndex === null && addedIndex === null) return output;

			const result = cloneDeep(output);
			let itemToAdd = payload.list[addedIndex];
			if (removedIndex !== null) {
				itemToAdd = result.splice(removedIndex, 1)[0];
			}
			if (addedIndex !== null) {
				result.splice(addedIndex, 0, itemToAdd);
			}
			return result;
		},
	},
	created() {
		this.reminder.type = 1;
	},

	mounted() {
		this.getReminderSetting();
		this.reminder.attendees = [this.userId];
		if (this.viewType == true) {
			const { query } = this.$route;
			this.getLeadListing(query);
			LeadEventBus.$on("table-lead-refresh", () => {
				this.getLeadListing();
			});
		}

		this.viewType = this.$route.query.viewType;

		this.$emit("loaded", true);
	},
	components: {
		Dialog,
		FullDialog,
		UpdateLead,
		LeadKanbanList,
		/* DatePicker, */
		AutoCompleteInput,
		TextInput,
		DeleteTemplate,
		AddDialog,
		AllNotesTab,
		CreateDialog,
		DateTimePicker,
		/* ManageNote,
		CreateReminder, */
	},
};
</script>
